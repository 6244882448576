<template>
  <v-container>
    <v-card
      :loading="loading"
      class="mx-auto"
      width="374"
      elevation="0"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title text-center teal--text">
            Записи на завтра
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <RecordList
        :record-list="records"
        :dates="[date]"
        :selectable="false"
      />

    </v-card>
  </v-container>
</template>

<script>
import dateFilter from "../../filters/dateFilter";
import RecordList from "../../components/RecordList";

export default {
  name: "TomorrowRecords",
  components: {RecordList},
  data: () => ({
    loading: true,
    records: [],
    empty: false,
    date: ""
  }),

  methods: {
    async loadRecordList() {
      const json = await this.authFetch(
        '/record/load-record-list-on-date', {date: this.date})
      if (!json.records.length) {
        this.empty = true
        return
      }
      this.records = json.records
      this.records.sort(this.sortRecordListFunc)
    },

    moveBack() {
      this.$router.push('/options')
    },
  },
  async mounted() {
    this.date = dateFilter(Date.now() + 86400000, 'ISO') // magick number - milliseconds in one day
    await this.loadRecordList()
    this.loading = false
  },
}
</script>

<style scoped>

</style>
